<template>
  <div class="auther-plain-container">
    <div class="top">
      <div class="item">
        <p class="num">{{ introduceObj.AchievementCount }}</p>
        <p class="text">学术成果</p>
      </div>
      <div class="item">
        <p class="num">{{ introduceObj.readCount }}</p>
        <p class="text">阅读</p>
      </div>
      <div class="item">
        <p class="num">{{ introduceObj.downloadCount }}</p>
        <p class="text">下载</p>
      </div>
    </div>
    <div class="box">
      <div class="item">
        <div class="title">擅长领域</div>
        <div class="content btns" v-if="keywordList.length > 0">
          <Button type="dashed" v-for="(v, i) in keywordList" :key="i">{{
            v
          }}</Button>
        </div>
        <div class="notData" style="margin-top: 30px" v-else>
          - 暂无选择擅长领域 -
        </div>
      </div>
      <div class="item">
        <div class="title">个人简介</div>
        <div class="content" v-if="autherObj.content && autherObj.content.profile">
          {{ autherObj.content.profile }}
        </div>
        <div class="notData" style="margin-top: 30px" v-else>
          - 暂无编辑个人简介 -
        </div>
      </div>
      <div class="item" v-if="statusList[1] && statusList[1].status === 1">
        <div class="title">教育背景</div>
        <div v-if="autherObj1.data && autherObj1.data.content" class="content" >
          <p>{{autherObj1.data.content}}</p>
        </div>
        <div v-else class="notData" style="margin-top: 30px" >
          - 暂无编辑教育背景 -
        </div>
      </div>
      <div class="item" v-if="statusList[2] && statusList[2].status === 1">
        <div class="title">工作经历</div>
        <div class="content" v-if="autherObj2.data && autherObj2.data.content">
          <p >{{autherObj2.data.content}}</p>
        </div>
        <div class="notData" style="margin-top: 30px" v-else>
          - 暂无编辑工作经历 -
        </div>
      </div>
      <div class="item" v-if="statusList[3] && statusList[3].status === 1">
        <div class="title">职称经历</div>
        <div class="content" v-if="autherObj3.data  && autherObj3.data.content">
          <p>{{autherObj3.data.content}}</p>
        </div>
        <div class="notData" style="margin-top: 30px" v-else>
          - 暂无编辑职称经历 -
        </div>
      </div>
      <div class="item" v-if="statusList[4] && statusList[4].status === 1">
        <div class="title">社会任职</div>
        <div class="content" v-if="autherObj4.data && autherObj4.data.content">
          <p>{{autherObj4.data.content}}</p>
        </div>
        <div class="notData" style="margin-top: 30px" v-else>
          - 暂无编辑社会任职 -
        </div>
      </div>
      <div class="item" v-if="statusList[5] && statusList[5].status === 1">
        <div class="title">获奖情况</div>
          <div class="content" v-if="autherObj5.data && autherObj5.data.content">
            <p>{{autherObj5.data.content}}</p>
          </div>
        <div class="notData" style="margin-top: 30px" v-else>
          - 暂无编辑获奖情况 -
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      introduceObj: {},
      keywordList: [],
      autherObj: {},
      autherObj1: {},
      autherObj2: {},
      autherObj3: {},
      autherObj4: {},
      autherObj5: {},
      statusList: [],
    };
  },
  created() {
    this.getPrivacyList({
      userId: this.$route.query.id,
    });
    this.introduceCount({
      id: this.$route.query.id,
    });
    this.getKeywords({
      id: this.$route.query.id,
    });
    this.spaceScholarProfile({
      userId: this.$route.query.id,
    });
    this.spaceScholarEduExp({
      userId: this.$route.query.id,
    });
    this.spaceScholarWorkExp({
      userId: this.$route.query.id,
    });
    this.spaceScholarTitleExp({
      userId: this.$route.query.id,
    });
    this.spaceScholarMoonExp({
      userId: this.$route.query.id,
    });
    this.spaceScholarPrize({
      userId: this.$route.query.id,
    });
  },
  methods: {
    async getPrivacyList(data) {
      const resp = await this.$apis.autherServe.getPrivacyList(data);
      if (resp.resultCode === '200') {
        if (resp.data[0]) {
          this.statusList = [...resp.data[0].childList];
        }
      }
    },
    // 个人简历
    async spaceScholarProfile(data) {
      const resp = await this.$apis.autherServe.spaceScholarProfile(data);
      this.autherObj = resp;
    },
    // 教育背景
    async spaceScholarEduExp(data) {
      const resp = await this.$apis.autherServe.spaceScholarEduExp(data);
      if (resp.resultCode === '200') {
        this.autherObj1 = resp
      }
    },
    // 工作经历
    async spaceScholarWorkExp(data) {
      const resp = await this.$apis.autherServe.spaceScholarWorkExp(data);
      if (resp.resultCode === '200') {
        this.autherObj2 = resp
      }
    },
    // 职称经历
    async spaceScholarTitleExp(data) {
      const resp = await this.$apis.autherServe.spaceScholarTitleExp(data);
      if (resp.resultCode === '200') {
        this.autherObj3 = resp
      }
    },
    // 社会任职
    async spaceScholarMoonExp(data) {
      const resp = await this.$apis.autherServe.spaceScholarMoonExp(data);
      if (resp.resultCode === '200') {
        this.autherObj4 = resp
      }
    },
    async spaceScholarPrize(data) {
      const resp = await this.$apis.autherServe.spaceScholarPrize(data);
      if (resp.resultCode === '200') {
        this.autherObj5 = resp
      }
    },
    async introduceCount(data) {
      const resp = await this.$apis.autherServe.introduceCount(data);
      if (resp.resultCode === '200') {
        this.introduceObj = resp.data;
      }
    },
    async getKeywords(data) {
      const resp = await this.$apis.autherServe.getKeywords(data);
      if (resp.resultCode === '200') {
        this.keywordList = [...resp.data];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.auther-plain-container {
  width: 1040px;
  // min-height: 1205px;
  margin-bottom: 111px;
  .top {
    width: 100%;
    height: 100px;
    background: #00a4ff;
    border-radius: 12px 12px 0px 0px;
    display: flex;
    .item {
      width: 345px;
      border-right: 1px dashed #ffffff;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      .num {
        font-size: 30px;
        margin-right: 15px;
      }
      .text {
        font-size: 14px;
        margin-top: 10px;
      }
      &:last-child {
        border-right: none;
      }
    }
  }
  .box {
    padding: 30px;
    border-right: 1px dashed #999999;
    border-left: 1px dashed #999999;
    border-bottom: 1px dashed #999999;
    .item {
      margin-bottom: 50px;
      &:last-child {
        margin-bottom: 0;
      }
      .notData {
        color: #c5c8ce;
      }
      .title {
        font-size: 22px;
        color: #333333;
        &::after {
          content: "";
          display: inline-block;
          width: 0;
          height: 0;
          border: 5px solid;
          border-color: transparent transparent transparent #333;
          margin-left: 10px;
          vertical-align: middle;
        }
      }
      .content {
        margin-top: 29px;
        font-size: 16px;
        & p{
          white-space: pre-wrap	;
        }
        .info {
          text-indent: 1em;
        }
      }
      .btns {
        display: flex;
        flex-wrap: wrap;
        button {
          height: 56px;
          line-height: 56px;
          font-size: 18px;
          border-radius: 12px;
          margin: 0 30px 15px 0;
          &::v-deep span {
            max-width: 900px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>
